import { loginSession, sessionData } from "@components/solid/AuthContext";
import coreLogger from "@core/coreLogger";
import { createEffect, createRoot } from "solid-js";

/**
 * Core Modules that are loaded and intalized when a user is logged in
 *
 * - setupUserMediaQueue - Sets up persistent media queue for logged in user
 * - startQueueToPlayerManager - Starts the integration between player and queue
 */

// TODO: Login module should be framework agnostic and therefore should not be
// dependent on the solid framework. This can improve cold starts on first page load.

const logger = coreLogger.child({ module: "userFeaturesLoader" });

createRoot(() => {
  createEffect(() => {
    if (loginSession()?.isLoggedIn || sessionData()?.user.sub) {
      logger.debug(
        "UserFeaturesLoader: Started for new user with id",
        loginSession()?.user?.sub ?? sessionData()?.user.sub,
      );

      import("@core/userMediaQueue").then(({ setupUserMediaQueue }) => {
        logger.debug("setting up user media queue");
        setupUserMediaQueue();
      });

      import("@core/queueToPlayerManager").then(
        ({ default: startQueueToPlayerManager }) => {
          logger.debug("setting up queue to player manager");
          startQueueToPlayerManager();
        },
      );
    }
  });
});
